<template>
    <section id="audience">
      <card icon="heart"
            :headline="$t('visitors.audience.headline')"
            :description="$t('visitors.audience.description')"
      >
        <div class="audience-content">
          <gender />
          <age-group/>
          <new-vs-recurring/>
        </div>
      </card>
    </section>
</template>

<script>
    import Gender from "@/app/website-dev/components/audience/Gender";
    import AgeGroup from "@/app/website-dev/components/audience/AgeGroup";
    import NewVsRecurring from "@/app/website-dev/components/audience/NewVsRecurring";
    export default {
        name: "Audience",
        components: {NewVsRecurring, AgeGroup, Gender}
    }
</script>

<style lang="scss">
  #audience .audience-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
</style>
