<template>
  <article id="gender">
    <strong>{{$t('visitors.audience.gender')}}</strong>
  </article>
</template>

<script>
    export default {
        name: "Gender"
    }
</script>

<style scoped lang="scss">

</style>
