<template>
  <article id="age-group">
    <strong>{{$t('visitors.audience.age')}}</strong>
  </article>
</template>

<script>
    export default {
        name: "AgeGroup"
    }
</script>

<style scoped lang="scss">

</style>
