<template>
    <article id="new-vs-recurring">
      <strong>{{$t('visitors.audience.userType')}}</strong>
    </article>
</template>

<script>
    export default {
        name: "NewRecurring"
    }
</script>

<style scoped lang="scss">

</style>
